import React from "react";
import AndroidForm from "./AndriodForm";
import SplashScreen from "../../../../../components/Layout/Carousel";

import "./style.css";

const SplashScreenComponent = (props) => {
  const {
    getState,
    updateState,
  } = props;
  
  return (
    <div className="splash-wrapper">
      <AndroidForm
        getState={getState}
        updateState={updateState}
      />
    </div>
  );
};

const SplashScreenWrapper = () => {
  return {
    preview: SplashScreen,
    fields: (props) => <SplashScreenComponent {...props} />,
  };
};

export default SplashScreenWrapper;
