import React from "react";
import { Input, Upload } from "../../../../components/Form";
import Tabs from "../../../../components/Tabs";
import ImageSlideshow from "../../../../components/Layout/ImageSlideshow";
import { getMessage } from "../../../../lib/translator";

const ImageSlideShow = () => {
  return {
    preview: ImageSlideshow,
    fields: ({ getState, updateState, parent }) => {
      const activeIndex = Number(getState(["activeIndex"])) || 0;
      const emptySlide = {
        gifUrl: "",
        imageUrl: "",
        text: "",
        link: "",
      };
      const slides = getState(["images"]) || [emptySlide];
      const currentSlide = slides[activeIndex] || emptySlide;

      if (!getState(["images"])) {
        updateState(["images"], slides); // TODO: Move this to componentWillMount of layout
      }

      // Since updateStateRecursively had some issues with this structure
      const newState = (key, value) => {
        parent.setState((prevState) => {
          const newState = Object.assign({}, prevState);
          newState.values.images[activeIndex][key] = value;
          return prevState;
        });
      };
      const showDelete = slides && slides.length > 1;
      return (
        <div>
          <div className="flex-around">
            <Tabs
              items={slides.map((slide, index) => (
                <span>
                  slide {index + 1}
                  {showDelete && (
                    <span
                      className="delete-tab"
                      onClick={(e) => {
                        slides.splice(index, 1);
                        updateState(
                          ["activeIndex"],
                          index !== 0 && index === slides.length
                            ? index - 1
                            : index
                        );
                      }}
                    >
                      &times;
                    </span>
                  )}
                </span>
              ))}
              default={activeIndex}
              active={activeIndex}
              onClick={(value) => {
                updateState(["activeIndex"], value);
              }}
            />
            <button
              className="add-tab"
              onClick={(e) => {
                e.preventDefault();
                const slidesCount = slides.length;
                updateState(["images"], slides.concat(emptySlide));
                updateState(["activeIndex"], slidesCount);
              }}
            >
              + {getMessage("imageSlideShow.addSlide")}
            </button>
          </div>
          <div className="form-sections">
            <div className="form-section">
              <Input
                label="text"
                placeholder="Enter your description here"
                value={currentSlide["text"]}
                onChange={(value) => {
                  newState("text", value);
                }}
              />
              <Input
                label="subtitle"
                placeholder="Enter subtitle here"
                value={currentSlide["subtitle"]}
                onChange={(value) => {
                  newState("subtitle", value);
                }}
              />
              <Input
                label="link"
                placeholder="Enter your link"
                value={currentSlide["link"]}
                onChange={(value) => {
                  newState("link", value);
                }}
              />
            </div>
            <div className="form-section">
              <Upload
                label="image"
                placeholder="Click here to upload or drag your banners"
                value={
                  currentSlide["gifUrl"]
                    ? currentSlide["gifUrl"]
                    : currentSlide["imageUrl"]
                }
                onChange={(newValue) => {
                  const fileUrl = newValue?.url
                  if (fileUrl && fileUrl?.toLowerCase()?.endsWith(".gif")) {
                    newState("gifUrl", fileUrl);
                    newState("imageUrl", ""); 
                  } else {
                    newState("imageUrl", newValue);
                    newState("gifUrl", ""); 
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    },
  };
};
export default ImageSlideShow;
