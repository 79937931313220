import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListingPageWithRoutes from "../../../containers/ListingPage/listingRouter";
import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import RecipeForm from "./Form";
import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { Dialog } from "../../../components/Popup";
import { getMessage } from "../../../lib/translator";
import { exportText, importText } from "../../../components/RichEditor";
import API from "../../../lib/api";
import { get } from "../../../lib/storage";

import "./style.css";
import emptyIcon from "./recipe-empty.svg";

class RecipeActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusAction: null,
    };
    this.showStatusActionDialog = this.showStatusActionDialog.bind(this);
    this.hideStatusActionDialog = this.hideStatusActionDialog.bind(this);
  }
  showStatusActionDialog(statusAction) {
    this.setState({ statusAction });
  }
  hideStatusActionDialog() {
    this.setState({ statusAction: false });
  }
  setPublishStatus(isDraft) {
    let id = this.props.id;
    let api = new API({ url: `/recipe-service/recipe/${id}` });
    return api
      .put({ isDraft })
      .then((response) => {
        this.props.onAction(TABLE_ACTIONS.EDIT, { id }, { isDraft });
        this.hideStatusActionDialog();
      }, console.error)
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    let { id, isDraft } = this.props;
    return (
      <div>
        <Dialog
          show={this.state.statusAction}
          title={
            isDraft ? getMessage("are.you.sure") : getMessage("are.you.sure")
          }
          information={
            isDraft
              ? getMessage("marketing.recipe.dialogs.publish.message")
              : getMessage("marketing.recipe.dialogs.unpublish.message")
          }
          onOk={() => {
            this.props.isDraft
              ? this.setPublishStatus(false)
              : this.setPublishStatus(true);
          }}
          close={this.hideStatusActionDialog}
          closeText={getMessage("cancel.text")}
          okText={
            isDraft
              ? getMessage("marketing.recipe.table.action.publish")
              : getMessage("marketing.recipe.table.action.unpublish")
          }
        />
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`/marketing/recipe/edit/${id}`}>
              {getMessage("marketing.action.edit.text")}
            </Link>
          </DropDownItem>
          <DropDownItem>
            <div
              onClick={() => {
                this.showStatusActionDialog(id);
              }}
            >
              {isDraft
                ? getMessage("marketing.recipe.table.action.publish")
                : getMessage("marketing.recipe.table.action.unpublish")}
            </div>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              this.props.onAction(TABLE_ACTIONS.DELETE, { id });
            }}
          >
            {getMessage("delete.text")}
          </DropDownItem>
        </DropDown>
      </div>
    );
  }
}

function AddRecipe() {
  return (
    <div className="text-center">
      <Link to="/marketing/recipe/add" className="primary button">
        <span>+ </span>
        <span className="text">{getMessage("marketing.recipe.add.text")}</span>
      </Link>
    </div>
  );
}

const emptyState = {
  icon: emptyIcon,
  message: getMessage("marketing.recipe.helpItems.message"),
  additionalViews: [AddRecipe],
};

const tableProperties = {
  headers: [
    getMessage("marketing.title.heading.text"),
    getMessage("marketing.table.heading.recipe.description"),
    getMessage("marketing.table.heading.status"),
    getMessage("marketing.actions.text"),
  ],
  row: ({ id, name, createdAt, author, isDraft, onAction, description, hasInfluencer }) => (
    <Row>
      <Cell className="recipe-page-name">
        <Link to={`/marketing/recipe/edit/${id}`} className="recipe-title-link">
          {name}
        </Link>
      </Cell>
      <Cell className="recipe-page-description">
        {description ? description : null}
        { description && hasInfluencer && <strong >  - </strong>}
        {hasInfluencer && (
          <strong className="influencer-recipe-check">
            {getMessage("influencer.recipe.check")}
          </strong>
        )}
      </Cell>
      <Cell className="recipe-page-published">
        {isDraft ? (
          <small className="status-draft">
            {getMessage("marketing.recipe.table.status.draft")}
          </small>
        ) : (
          <small className="status-published">
            {getMessage("marketing.recipe.table.status.published")}
          </small>
        )}
      </Cell>

      <Cell className="recipe-page-actions">
        <RecipeActions onAction={onAction} id={id} isDraft={isDraft} />
      </Cell>
    </Row>
  ),
};

export default function Recipe(props) {
  return (
    <ListingPageWithRoutes
      helmetTitle="Zopping - Recipe"
      menu={props.menu}
      className="recipe-page"
      title="Recipes"
      addHeading={getMessage("marketing.recipe.form.add.heading")}
      editHeading={getMessage("marketing.recipe.form.edit.heading")}
      emptyState={emptyState}
      headerActions={AddRecipe}
      api={{
        url: "/recipe-service/recipe",
        headers: {
          "Accept-Language": get("dataLang") || "en",
        },
        transform: (response) => response.data.recipes,
      }}
      showLanguageDropDown
      form={{
        component: RecipeForm,
        transformSubmit: (formData) => {
          let {
            name,
            description,
            image,
            thumbnail,
            ingredients,
            directions,
            metadata,
            products,
          } = formData;

          let productIds = products
            ?.map((product) => String(product.id))
            .join(",");

          return {
            name,
            description,
            image,
            thumbnail,
            ingredients: exportText(importText(ingredients)), // using importText is a temporary fix for RichEditor state issue
            directions: exportText(importText(directions)),
            metadata: exportText(importText(metadata)),
            productIds,
          };
        },
        transformResponse: (response) => {
          return response?.data?.recipe || {};
        },
      }}
      tableProperties={tableProperties}
    />
  );
}
