import React, { Component, Fragment } from "react";
import AuthenticatedPage from "../../../containers/AuthenticatedPage";
import API from "../../../lib/api";
import Loader from "../../../components/Loader";
import Tabs from "../../../components/Tabs";
import {
  SingleDatePicker,
  Select,
  CategorySearch,
  BrandSearch,
  Searchable,
  Checkbox,
} from "../../../components/Form";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import { getMessage } from "../../../lib/translator";
import { isExtensionEnabled } from "../../../lib/auth";
import { Link } from "react-router-dom";
import { getTimes } from "../../../lib/datetime";
import moment from "moment";
import { DropDown, DropDownItem } from "../../../components/DropDown";

import Layout from "./layout";
import SplashScreen from "../../../components/Layout/SplashScreen";
import ImageSlideshow from "../../../components/Layout/ImageSlideshow";
import AppointmentForm from "../../../components/Layout/AppointmentForm";
import Banner from "../../../components/Layout/Banner";
import ImageWithText from "../../../components/Layout/ImageWithText";
import ProductCollection from "../../../components/Layout/ProductCollection";
import { Popup, Dialog } from "../../../components/Popup";
import "./style.css";
import getLayout from "./Layouts/getLayout";
import DualImageGrid from "../../../components/Layout/DualImageGrid";
import arrow from "../../../components/Form/Inputs/Select/dropdown arrow.svg";
import deleteIcon from "./cx-delete-icon.svg";
import Carousel from "../../../components/Layout/Carousel";

import DualImageBanner from "../../../components/Layout/DualImageBanner";
import checkHttpResponseCode from "../../../utils/isApiCallSuccessfull";
import Testimonial from "../../../components/Layout/Testimonial";
import RichText from "../../../components/Layout/RichText";

import { updateStateRecursively } from "../../../lib/stateManagement";
import { exportText } from "../../../components/RichEditor";

import {
  BannerWithText,
  BannerWithMultipleButtons,
  VideoCardCarousel,
} from "zopsmart-ui";
import AnnouncementBar from "./AnnouncementBar";
import { get } from "../../../lib/storage";
import GridCollection from "../../../components/Layout/GridCollection";
import FooterLayout from "../../../components/Layout/Footer";
import { faro } from "@grafana/faro-web-sdk";
import { specialLayout } from "./constants";

// Support only for Fashion Trends theme as of now
const dualImageLayouts = ["DualImageBanner", "DualImage"];

const AppointmentFormLayout = "AppointmentForm";

const ANNOUNCEMENT_BAR_STATUS = {
  enabled: "ENABLED",
  disabled: "DISABLED",
};

// serchable component for brand and category
const searchableComponent = {
  brand: BrandSearch,
  category: CategorySearch,
};

// Layout components mapping with their names
const availableComponents = {
  Images: {
    ImageSlideShow: ImageSlideshow,
    ImageWithText: ImageWithText,
    DualImageBanner: DualImageBanner,
    GridCollection: GridCollection,
  },
  "E-commerce": {
    ProductCollection: ProductCollection,
    CategoryCollection: ProductCollection,
    BrandCollection: ProductCollection,
    SearchResults: ProductCollection,
    DualImage: DualImageGrid,
  },
  Banners: {
    MarketingBanners: Banner,
    BannerWithText: BannerWithText,
    BannerWithButton: BannerWithText,
    BannerWithMultipleButtons: BannerWithMultipleButtons,
  },
  Videos: {
    VideoCardCarousel: VideoCardCarousel,
  },
  Forms: {
    AppointmentForm: AppointmentForm,
  },
  Text: {
    RichText: RichText,
  },
  Carousel: {
    Carousel: Carousel,
    Testimonial: Testimonial,
  },
  Blog: {
    BlogCollection: ProductCollection,
  },
  Seller: {
    SellerCollection: ProductCollection,
  },
  FooterLayout: {
    FooterLayout: FooterLayout,
  },
  Splash: {
    Splash: SplashScreen,
  },
  Influencer: {
    InfluencerCollection: ProductCollection,
  },
};

const DragHandle = SortableHandle((props) => (
  <span className="sortable-handle">{props.children}</span>
));

const SortableItem = SortableElement((props) => {
  const { value, layoutIndex, onEditClick, onRemoveClick } = props;
  let LayoutComponent = "";
  Object.keys(availableComponents).forEach(function (component, index) {
    if (availableComponents[component][value.name]) {
      LayoutComponent = availableComponents[component][value.name];
    }
  });
  let element = "";
  const isShowLayout = typeof LayoutComponent !== "string";
  return (
    <div className="layoutItem">
      {isShowLayout && (
        <>
          <h3>
            <DragHandle>
              {value.name.replace(/([A-Z])/g, " $1").trim()}
            </DragHandle>
          </h3>
          <div
            className={value.name + " editable layout"}
            ref={(el) => {
              element = el;
            }}
          >
            <LayoutComponent data={value.data} />
            <div className="toolbar">
              {!["MarketingBanners"].includes(value.name) && (
                <button
                  className="primary button"
                  onClick={(e) => {
                    onEditClick(layoutIndex, element.offsetTop);
                  }}
                >
                  Edit
                </button>
              )}
              {value?.name !== "Splash" && (
                <button
                  className="button"
                  onClick={(e) => {
                    onRemoveClick(layoutIndex);
                  }}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
});

const SortableList = SortableContainer((props) => {
  return (
    <div className="layoutsContainer">
      {Array.isArray(props.items) &&
        props.items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            layoutIndex={index} // for some reason index was not being sent as props
            {...props}
          />
        ))}
    </div>
  );
});

export default class LayoutManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      loaded: false,
      editing: false,
      submitting: false,
      showDialog: false,
      showPrompt: false,
      category: "",
      brand: "",
      tag: "",
      categoriesDataLoaded: false,
      tagsDataLoaded: false,
      brandsDataLoaded: false,
      currentComponent: "Images",
      timeLine: "default",
      errors: {},
      showFilter: false,
      announcementBar: {},
      isAnnouncementBarEnabled: false,
      values: {
        description: "",
      },
      showLoader: true,
      showSaveDialog: false,
      showErrorDialog: false,
    };
    this.pages = ["home", "category"];
    const productTagEnabled = isExtensionEnabled("ProductTagSupport");
    const brandsEnabled = isExtensionEnabled("MultiBrandSupport");

    if (productTagEnabled) {
      this.pages.push("tag");
    }
    if (brandsEnabled) {
      this.pages.push("brand");
    }

    this.pages.push("search");
    if (isExtensionEnabled("RecipePageGenerator")) {
      this.pages.push("influencer");
    }
    this.pages.push("footer");
    this.pages.push("Splash Screen");

    this.data = {}; // Discuss: Didn't want to put this in state
    this.currentLayout = {};
    this.selectOptionsData = {
      categories: [],
      tags: [],
      brands: [],
    };
    this.listView = this.listView.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onRemoveClick = this.onRemoveClick.bind(this);
    this.showPrompt = this.showPrompt.bind(this);
    this.updateLayouts = this.updateLayouts.bind(this);
    this.getSelectOptionsData = this.getSelectOptionsData.bind(this);
    this.getSelectElement = this.getSelectElement.bind(this);
    this.formatResponse = this.formatResponse.bind(this);
    this.reorderLayout = this.reorderLayout.bind(this);
    this.cancelReordering = this.cancelReordering.bind(this);
    this.setLayoutSchedulerValues = this.setLayoutSchedulerValues.bind(this);
    this.setLayoutTimeline = this.setLayoutTimeline.bind(this);
    this.fetchScheduledLayout = this.fetchScheduledLayout.bind(this);
    this.handleDeleteSceduledLayout =
      this.handleDeleteSceduledLayout.bind(this);
    this.fetchWrapper = this.fetchWrapper.bind(this);
  }

  componentDidMount() {
    if (!isExtensionEnabled("MarketPlace")) {
      delete availableComponents["Seller"];
    }
    if (!isExtensionEnabled("RecipePageGenerator")) {
      delete availableComponents["Influencer"];
    }
    this.fetchAnnouncementBarData();
    this.fetchWrapper();
  }

  fetchAnnouncementBarData() {
    const api = new API({
      url: `/website-service/announcement`,
    });
    api
      .get("", {
        "Accept-Language": get("dataLang") || "en",
      })
      .then((response) => {
        const { description, status } = response.data;
        if (status === ANNOUNCEMENT_BAR_STATUS.enabled && description) {
          this.setState({
            isAnnouncementBarEnabled: true,
            announcementBar: {
              description: description,
            },
            values: {
              ...this.state.values,
              description: description,
            },
          });
        } else {
          this.setState({
            isAnnouncementBarEnabled: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({
          showLoader: false,
        });
      });
  }

  // This method can be removed later once the scheduler api will be supported for all pages
  fetchWrapper() {
    this.fetchScheduledLayout();
  }

  formatResponse(response) {
    let newResponse = Object.assign([], response);
    const page = this.pages[this.state.page];
    newResponse.forEach((layout) => {
      if (
        layout.name === "CategoryCollection" ||
        layout.name === "BrandCollection"
      ) {
        let key = layout.name === "CategoryCollection" ? "category" : "brand";
        let collection = (layout.value && layout.value.collection) || [];
        collection = collection.map((val) => val[key]);
        layout["data"][key] = collection;
        layout.value && delete layout.value.collection;
      }
      if (
        layout.name === "CategoryCollection" &&
        layout.data &&
        layout.data.subcategories &&
        page === "category"
      ) {
        layout.name = "SubCategoryCollection";
      }
    });
    return newResponse;
  }

  fetchScheduledLayout() {
    this.setState({
      loaded: false,
    });
    const page = this.pages[this.state.page];
    let timeline = this.state.timeLine;
    const urlMap = {
      footer: `/account-service/footer`,
      "Splash Screen":
        "/config-service/config?category=androidAppConfig,iosAppConfig",
    };
    let url = urlMap[page] || `/website-service/layout`;
    let params = specialLayout?.includes(page) ? {} : { id: page };

    if (timeline !== "new") {
      params.validFrom = timeline === "default" ? null : timeline.split("/")[0];
      params.validTill = timeline === "default" ? null : timeline.split("/")[1];
    }

    if (this.state[page]) {
      params.url = this.state[page];
    }

    const api = new API({
      url: url,
    });
    api
      .get(params, {
        "Accept-Language": get("dataLang") || "en",
      })
      .then((response) => {
        if (response.status === "ERROR") {
          console.error(`Error in fetching details for ${page}`); // Todo: Handle this error
          return;
        }
        if (
          (response?.data === null && specialLayout?.includes(page)) ||
          (!response.data.page?.length && !specialLayout?.includes(page))
        ) {
          this.data = [];
          this.originalData = [];
          this.setState({ loaded: true });
          return;
        }
        if (page !== "search") {
          delete availableComponents["E-commerce"]["SearchResults"];
        } else {
          availableComponents["E-commerce"]["SearchResults"] =
            ProductCollection;
        }

        if (page !== "category") {
          delete availableComponents["E-commerce"]["SubCategoryCollection"];
        } else {
          availableComponents["E-commerce"]["SubCategoryCollection"] =
            ProductCollection;
        }
        this.pageId =
          page === "footer" ? null : response?.data?.page?.[0]?.pageId;
        this.data = this.formatResponse(response?.data?.page?.[0]?.layouts);
        if (page === "Splash Screen") {
          let splashObjectData = {
            name: "Splash",
            data: response.data,
          };
          this.pageId = page;
          this.data = [splashObjectData];
          this.originalData = this.data;
        } else if (page === "footer") {
          let footerLayoutData = {
            name: "FooterLayout",
            data: {
              id: response?.data?.id,
              sections: response?.data?.layouts,
            },
          };

          this.data = [footerLayoutData];
          this.originalData = this.data;
        } else {
          this.pageId = response?.data?.page?.[0]?.pageId;
          this.data = this.formatResponse(response.data.page[0].layouts);
          this.originalData = this.data;
          if (!this.state.categoryDataLoaded) {
            this.getSelectOptionsData("category", "categories");
          }
          if (this.pages.includes("tag")) {
            if (!this.state.tagDataLoaded) {
              this.getSelectOptionsData("tag", "tags");
            }
          }
          if (this.pages.includes("brand")) {
            if (!this.state.brandDataLoaded) {
              this.getSelectOptionsData("brand", "brands");
            }
          }
        }
        let validFrom = specialLayout?.includes(page)
          ? ""
          : response?.data?.page?.[0]?.validFrom;
        let validTill = specialLayout?.includes(page)
          ? ""
          : response?.data?.page?.[0]?.validTill;

        this.setState(
          {
            loaded: true,
            fromDate: validFrom && moment(validFrom).format("YYYY-MM-DD"),
            fromTime: validFrom && moment(validFrom).format("HH:mm:ss"),
            toDate: validTill && moment(validTill).format("YYYY-MM-DD"),
            toTime: validTill && moment(validTill).format("HH:mm:ss"),
          },
          () => {
            timeline !== "new" &&
              isExtensionEnabled("LayoutSchedulingSupport") &&
              this.fetchLayoutTimeLine();
            this.getAppointmentFormData();
          }
        );
      })
      .catch((error) => {
        this.data = [];
        this.setState({ loaded: true });
        console.error(error);
      });
  }

  getAppointmentFormData = async () => {
    let layoutNames = this.data && this.data.map(({ name }) => name);
    if (layoutNames.includes(AppointmentFormLayout)) {
      let configAPI = new API({
        url: "/config-service/config/website",
      });
      try {
        let resp = await configAPI.get();
        this.data =
          this.data &&
          this.data.map((layout) => {
            if (layout.name === AppointmentFormLayout) {
              const { googleCaptchaKey = "", googleCaptchaSecret = "" } =
                resp?.data?.website;
              layout.data = {
                ...layout.data,
                googleCaptchaKey: googleCaptchaKey,
                googleCaptchaSecret: googleCaptchaSecret,
              };
            }
            return layout;
          });
      } catch (error) {
        this.setState({
          error: `${error.message}`,
        });
        console.error(error);
      }
    }
  };

  fetchLayoutTimeLine() {
    let page = this.pages[this.state.page];
    if (specialLayout?.includes(page)) return;
    let url = `/website-service/layout/${page}/date`;
    let params = this.state[page] && {
      url: this.state[page],
    };
    const api = new API({ url });

    api
      .get(params, {
        "Accept-Language": get("dataLang") || "en",
      })
      .then((resp) => {
        let timeLines = resp.data.date;
        timeLines =
          timeLines &&
          timeLines
            .filter((time) => time.validTill && time.validFrom)
            .map((time) => {
              let startDatetime = moment(time.validFrom);
              let endDatetime = moment(time.validTill);
              return {
                value: time.validFrom + "/" + time.validTill,
                text: (
                  <Fragment>
                    {startDatetime.format("Do MMM")}
                    <span className="layout-time">
                      {startDatetime.format("hh:mm A")}
                    </span>
                    {" - "}
                    &nbsp;
                    {endDatetime.format("Do MMM")}
                    <span className="layout-time">
                      {endDatetime.format("hh:mm A")}
                    </span>
                  </Fragment>
                ),
              };
            });

        timeLines = [
          { text: "Default", value: "default" },
          ...timeLines,
          { text: "Add New", value: "new" },
        ];

        this.setState({ timeLines });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          error: `Unable to fetch timeline dates: ${error.message}`,
        });
      });
  }

  updateState = (keys, value, registerAsUserInput = true) => {
    this.setState({
      pressedSubmitWithCurrentData: false,
    });
    updateStateRecursively.call(this, ["values", ...keys], value);
    if (registerAsUserInput) {
      updateStateRecursively.call(this, ["touched", ...keys], true);
    }

    this.setState({
      announcementBar: {
        description: exportText(value),
      },
    });
  };

  handleOnChange = () => {
    this.setState({
      isAnnouncementBarEnabled: !this.state.isAnnouncementBarEnabled,
    });
  };

  handleAnnouncementBarSave = () => {
    let payloadData;
    if (this.state.isAnnouncementBarEnabled) {
      payloadData = {
        description: exportText(this.state.values.description),
        status: ANNOUNCEMENT_BAR_STATUS.enabled,
      };
    } else {
      payloadData = {
        description: null,
        status: ANNOUNCEMENT_BAR_STATUS.disabled,
      };
      this.setState({
        isAnnouncementBarEnabled: false,
      });
    }
    const api = new API({ url: "/website-service/announcement" });
    if (
      !this.state.isAnnouncementBarEnabled ||
      (this.state.isAnnouncementBarEnabled && payloadData.description)
    ) {
      this.setState({
        announcementBarSubmitting: true,
      });
      api
        .post(payloadData, {
          "Accept-Language": get("dataLang") || "en",
        })
        .then(() => {
          this.setState({
            showSaveDialog: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            showErrorDialog: true,
          });
        })
        .finally(() => {
          this.setState({
            announcementBarSubmitting: false,
          });
        });
    }
  };

  getSelectOptionsData(selectField, dataFor) {
    const api = new API({
      url: `/catalogue-service/${selectField}`,
    });

    api
      .get()
      .then((response) => {
        if (response.status === "ERROR") {
          console.error(`Error in fetching details of ${dataFor}`);
          return;
        }
        this.selectOptionsData[dataFor] = [];
        if (!response.data[selectField]) {
          response.data[selectField] = [];
        }
        this.selectOptionsData[dataFor] = response.data[selectField].map(
          function (field) {
            return { text: field.name, value: field.slug };
          }
        );
        this.selectOptionsData[dataFor].push(
          { text: `All ${dataFor}`, value: "ALL" },
          { text: `Current ${selectField}`, value: "CURRENT" }
        );
        const state = `${dataFor}DataLoaded`;
        this.setState({
          loaded: true,
          [state]: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  changePage(page) {
    this.setState(
      {
        page: page,
        timeLine: "default",
        timeLines: [],
        fromDate: "",
        toDate: "",
        fromTime: "",
        toTime: "",
        currentComponent:
          this.pages.length - 2 === page ? "FooterLayout" : "Images",
      },
      this.fetchWrapper
    );
  }

  reorderLayout() {
    this.saveLayouts();
  }

  cancelReordering() {
    this.data = this.originalData;
    this.forceUpdate();
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.data = arrayMove(this.data, oldIndex, newIndex);
    this.forceUpdate();
  }

  appointmentForm = async (formData) => {
    if (!formData) {
      return;
    }

    let configAPI = new API({
      url: "/config-service/config",
    });

    let { googleCaptchaKey, googleCaptchaSecret } = formData;

    let website = { googleCaptchaKey, googleCaptchaSecret };
    try {
      let resp = await configAPI.post({ website });
      if (checkHttpResponseCode(resp.code)) {
        this.data =
          this.data &&
          this.data.map((layout) => {
            if (layout.name === AppointmentFormLayout) {
              layout.data = { ...website };
            }
            return layout;
          });
      }
      this.setState({
        submitting: false,
        editing: false,
        showDialog: true,
      });
    } catch (error) {
      this.setState({
        showDialog: true,
        error: error.message,
        submitting: false,
      });
      console.error(error);
    }
  };

  mappingPayload(formData) {
    const androidAppConfig = { ...formData?.config?.androidAppConfig };
    const iosAppConfig = { ...formData?.config?.iosAppConfig };
    const getAndriodAppConfig = () => {
      const splashResolutionsAndroid = {};
      let splash =
        formData?.config?.androidAppConfig?.splashMdpi?.imageUpload
          ?.resizedUrls;
      for (let key in splash) {
        const mappingOne = {
          "200x320": "Ldpi",
          "320x480": "Mdpi",
          "480x800": "Hdpi",
          "720x1280": "Xhdpi",
          "960x1600": "Xxhdpi",
          "1280x1920": "Xxxhdpi",
        };
        const resolution = mappingOne?.[key];

        if (resolution) {
          splashResolutionsAndroid[`splash${resolution}`] = splash?.[key]?.[0]; // Assign to Android
        }
      }
      Object.assign(androidAppConfig, splashResolutionsAndroid);
      return androidAppConfig;
    };

    const getIosAppConfig = () => {
      const splashResolutionsIOS = {};
      let splashIOS =
        formData?.config?.iosAppConfig?.splashMdpi?.imageUpload?.resizedUrls;
      for (let key in splashIOS) {
        const mappingTwo = {
          "640x1136": "640x1136",
          "750x1334": "750x1334",
          "828x1792": "828x1792",
          "1125x2436": "1125x2436",
          "1242x2208": "1242x2208",
          "1242x2688": "1242x2688",
          "1536x2048": "1536x2048",
          "1668x2224": "1668x2224",
          "2048x2732": "2048x2732",
        };
        const resolution = mappingTwo?.[key];
        if (resolution) {
          splashResolutionsIOS[`splash${key}`] = splashIOS?.[key]?.[0]; // Assign to Ios
        }
      }
      Object.assign(iosAppConfig, splashResolutionsIOS);
      delete iosAppConfig.splashMdpi;
      return iosAppConfig;
    };

    return {
      androidAppConfig: getAndriodAppConfig(),
      iosAppConfig: getIosAppConfig(),
    };
  }

  saveLayouts(index, formData = null, isDelete = false) {
    let { fromDate, fromTime, toDate, toTime, timeLine } = this.state;
    const page = this.pages[this.state.page];
    let footerId = this.data?.[0]?.data?.id ?? "";
    this.setState({
      submitting: true,
    });

    if (formData && formData.name === AppointmentFormLayout) {
      this.appointmentForm(formData);
    }
    let data = this.data;
    if (!formData) {
      data =
        index !== undefined
          ? this.data.filter((_, idx) => idx !== index)
          : this.data;
    } else {
      data = data.filter((_, i) => i !== index);
      let layoutname = formData.name;
      delete formData.name;
      data.splice(index, 0, { name: layoutname, data: { ...formData } });
    }
    if (!index && index !== 0) {
      data = this.data;
    }
    if (page === "footer" && isDelete) {
      const api = new API({ url: "/account-service/footer" });
      this.setState({ loaded: false, showDeleteLayoutPopup: false });
      if (!footerId) {
        this.data = this.data.filter(
          (layout) => layout.name !== "FooterLayout"
        );
        this.setState({
          loaded: true,
          timeLine: "default",
          editing: false,
          submitting: false,
          showDialog: true,
          error: false,
        });
        return;
      }
      api
        .delete()
        .then(() => {
          this.data = [];
          this.setState({
            loaded: true,
            timeLine: "default",
            editing: false,
            submitting: false,
            showDialog: true,
            error: false,
          });
        })
        .catch((error) => {
          this.setState({
            showDialog: true,
            error: error.message,
            submitting: false,
          });
          if (faro?.api?.pushError) {
            faro.api.pushError(error);
          }
        });
      return;
    }
    if (page === "Splash Screen") {
      const { iosAppConfig, androidAppConfig } = this.mappingPayload(
        data?.[0]?.data
      );

      const api = new API({
        url: "/config-service/config",
      });
      this.setState({ loaded: false, submitting: true, showDialog: false });
      api
        .post({ androidAppConfig, iosAppConfig })
        .then(() => {
          this.setState({
            loaded: true,
            timeLine: "default",
            editing: false,
            submitting: false,
            showDialog: true,
            error: false,
          });
        })
        .catch((error) => {
          this.setState({
            showDialog: true,
            error: "Something went wrong",
            submitting: false,
            loaded: true,
          });
          console.error(error);
          throw error;
        });
      return;
    }

    let url =
      page === "footer"
        ? "/account-service/footer"
        : `/website-service/layout/${page}`;
    data &&
      data.forEach((layout, i) => {
        const layoutName = layout.name;
        if (layoutName === "SubCategoryCollection") {
          layout.name = "CategoryCollection";
          layout.data.subcategories = true;
        }

        if (layoutName === "CategoryCollection") {
          if (formData && i === index) {
            layout.data.categoryIds =
              layout.data.category &&
              layout.data.category
                .map((category) => category.id)
                .filter(Boolean);
            delete layout.data.category;
          }
        }
        if (layoutName === "BlogCollection") {
          if (formData && i === index) {
            layout.data.blogIds =
              layout.data.blog &&
              layout.data.blog.map((blog) => blog.id).filter(Boolean);
            delete layout.data.blog;
          }
        }
        if (layoutName === "BrandCollection") {
          if (formData && i === index) {
            layout.data.brandIds =
              layout.data.brand &&
              layout.data.brand.map((brand) => brand.id).filter(Boolean);
          }
          delete layout.data.brand;
        }
        if (layoutName === "ProductCollection") {
          layout.data && delete layout.data.collection;
          if (layout.data.hasImage === -1) {
            layout.data.hasImage = "";
          }
          if (layout.data.hasStock === -1) {
            layout.data.hasStock = "";
          }
          if (layout.data.hasOffers === -1) {
            layout.data.hasOffers = "";
          }
          if (
            layout.data.category &&
            typeof layout.data.category === "object"
          ) {
            layout.data.category = layout.data.category.slug;
          }
          if (layout.data.brand && typeof layout.data.brand === "object") {
            layout.data.brand = layout.data.brand.slug;
          }
          if (layout.data.tag && typeof layout.data.tag === "object") {
            layout.data.tag = layout.data.tag.slug;
          }
        }
        if (layout.name === "ImageSlideShow") {
          layout.data &&
            layout.data.activeIndex &&
            delete layout.data.activeIndex;
        }
        if (layout.name === "GridCollection") {
          layout.data &&
            layout.data.activeIndex &&
            delete layout.data.activeIndex;
        }

        if (layout.name === AppointmentFormLayout) {
          delete layout.data.googleCaptchaKey;
          delete layout.data.googleCaptchaSecret;
        }
        if (layout.name === "FooterLayout") {
          layout.data = layout.data.sections;
          layout.name = "footer";
        }
        delete layout?.value;
        layout.data && delete layout.data.collection;
      });

    const api = new API({
      url: url,
    });
    let params = {
      layouts: data,
    };

    if (page === "footer") {
      params = {
        layouts: data?.[0]?.data,
      };
    }

    if (fromDate && fromTime && toDate && toTime) {
      let fromTimeStamp = moment(fromDate + fromTime, ["YYYY-MM-DDHH:mm:ss"])
        .utc()
        .format();
      let toTimeStamp = moment(toDate + toTime, ["YYYY-MM-DDHH:mm:ss"])
        .utc()
        .format();

      if (timeLine === "new") {
        toTimeStamp = moment(toTimeStamp).subtract(1, "second").utc().format();
      }

      params.validFrom = fromTimeStamp;
      params.validTill = toTimeStamp;
    }
    // if (page === 'tag' && !this.state.hideFilter) {
    //   params.hideFilter = true
    // }
    if (page === "tag") {
      params.showFilter = this.state.showFilter;
    }
    if (
      page !== "home" &&
      this.state[page] !== "" &&
      this.state[page] !== "ALL" &&
      this.state[page]
    ) {
      params.url = `${this.state[page]}`;
    }
    if (page === "footer") {
      let method = footerId === "" ? "post" : "put";
      api[method](params, {
        "Accept-Language": get("dataLang") || "en",
      }).then(
        (response) => {
          const footerResponseObject = {
            name: "FooterLayout",
            data: {
              id: response?.data?.id,
              sections: response?.data?.layouts,
            },
          };
          this.data = [footerResponseObject];
          this.originalData = this.data;
          this.pageId = response?.data.page?.pageId;

          this.setState({
            editing: false,
            loaded: true,
            submitting: false,
            showDialog: true,
            error: false,
            errors: {},
          });
        },
        (error) => {
          this.setState({
            showDialog: true,
            loaded: true,
            error: error.message,
            submitting: false,
            editing: false,
          });
        }
      );
    } else if (
      timeLine !== "new" ||
      (timeLine === "new" && fromDate && fromTime && toDate && toTime)
    ) {
      api
        .put(params, {
          "Accept-Language": get("dataLang") || "en",
        })
        .then(
          (response) => {
            this.data = this.formatResponse(response?.data?.page?.layouts);
            this.originalData = this.data;
            let validFrom = response?.data?.page?.validFrom;
            let validTill = response?.data?.page?.validTill;
            let fromDate = validFrom && moment(validFrom).format("YYYY-MM-DD");
            let fromTime = validFrom && moment(validFrom).format("HH:mm:ss");
            let toDate = validTill && moment(validTill).format("YYYY-MM-DD");
            let toTime = validTill && moment(validTill).format("HH:mm:ss");

            let timeLine =
              validFrom && validTill ? validFrom + "/" + validTill : "default";

            this.pageId = response?.data?.page?.pageId;

            this.setState(
              {
                editing: false,
                submitting: false,
                showDialog: true,
                error: false,
                fromDate,
                fromTime,
                toDate,
                toTime,
                timeLine: timeLine,
                errors: {},
              },
              () => {
                this.fetchLayoutTimeLine();
                this.getAppointmentFormData();
              }
            );
          },
          (error) => {
            this.setState({
              showDialog: true,
              error: error.message,
              submitting: false,
            });
          }
        )
        .catch((error) => {
          console.error(error);
        });
    } else if (timeLine === "new") {
      this.data = data;
      window.scrollTo(0, 0);
      this.setState((prevState) => {
        let newState = Object.assign({}, prevState);
        newState.editing = false;
        newState.error = {};
        if (!newState.fromDate) {
          newState.errors.fromDate = true;
        }
        if (!newState.toDate) {
          newState.errors.toDate = true;
        }
        if (!newState.fromTime) {
          newState.errors.fromTime = true;
        }
        if (!newState.toTime) {
          newState.errors.toTime = true;
        }
        return newState;
      });
    }
  }

  onAddClick() {
    this.setState({
      showLayoutPopup: true,
    });
  }

  addLayout(layoutName, data) {
    const layout = {
      name: layoutName,
      data: data,
    };
    this.data.push(layout);
    this.setState({
      showLayoutPopup: false,
    });
  }

  onEditClick(layoutIndex, top) {
    const layout = this.data[layoutIndex];
    Object.assign(this.currentLayout, layout);
    this.currentLayout.top = top;
    this.setState({
      editing: true,
      layoutIndex,
    });
  }

  onRemoveClick(index) {
    this.saveLayouts(index, null, true);
    this.setState({
      showPrompt: false,
      deleteIndex: "",
    });
  }

  showPrompt(index) {
    this.setState({
      showPrompt: true,
      deleteIndex: index,
    });
  }

  updateLayouts(value) {
    const state = this.pages[this.state.page];
    this.setState(
      {
        [state]: value,
      },
      this.fetchScheduledLayout
    );
  }

  getRespectiveComponents(component) {
    this.setState({
      currentComponent: component,
    });
  }

  getSelectElement(selectField, dataFor) {
    let SearchableComponent = searchableComponent[selectField];
    return selectField !== "tag" ? (
      <SearchableComponent
        className="taxonomy-select"
        label={getMessage("themes.searchable.label")}
        placeholder={getMessage(`themes.${selectField}.show.layout`)}
        value={this.state[`${selectField}Object`]}
        dontShowChain={selectField === "category"}
        onChange={(value) => {
          this.setState({ [`${selectField}Object`]: value });
          this.updateLayouts(value.slug);
        }}
      />
    ) : (
      <Searchable
        label={getMessage("themes.searchable.label")}
        placeholder={getMessage(`themes.${selectField}.show.layout`)}
        className="taxonomy-select"
        searchUrl="/catalogue-service/tag"
        valueKey="id"
        nameKey="name"
        searchKey="name"
        transformResponse={(response) => response.data.tag}
        value={this.state[`${selectField}Object`]}
        onChange={(value) => {
          this.setState({ [`${selectField}Object`]: value });
          this.updateLayouts(value.slug);
        }}
      />
    );
  }

  setLayoutSchedulerValues(key, value) {
    this.setState({
      [key]: value,
    });
  }

  handleDeleteSceduledLayout() {
    let pageId = this.pageId;
    let page = this.pages[this.state.page];
    let api = new API({ url: `/website-service/layout/${page}` });
    this.setState({ loaded: false, showDeleteLayoutPopup: false }, () => {
      api
        .delete(
          { pageId },
          {
            "Accept-Language": get("dataLang") || "en",
          }
        )
        .then((response) => {
          this.setState(
            { loaded: true, timeLine: "default" },
            this.fetchWrapper
          );
        })
        .catch((error) => {
          console.error(error);
          this.setState({ error: error.message });
        });
    });
  }

  setLayoutTimeline(value) {
    this.setState({ timeLine: value }, () => {
      if (value === "new") {
        this.setState(
          {
            showNewLayoutForm: true,
            fromDate: "",
            fromTime: "",
            toDate: "",
            toTime: "",
          },
          this.fetchScheduledLayout
        );
      } else if (value !== "default") {
        this.fetchScheduledLayout();
      } else {
        this.changePage(this.state.page);
      }
    });
  }

  getSchedulerForm() {
    return (
      <div
        className={
          "layout-scheduler-container" +
          (this.state.timeLine === "new" ? " bottom" : "")
        }
      >
        <div className="timeline-block">
          <div className="timeline-date">
            <label htmlFor="startDate">
              {getMessage("themes.layout.timeline.startDate.heading")}
            </label>
            <SingleDatePicker
              value={this.state.fromDate}
              id="startDate"
              onChange={(value) =>
                this.setLayoutSchedulerValues("fromDate", value)
              }
              enableToday
              required
              showError={this.state.errors.fromDate}
            />
          </div>
          <Select
            label={getMessage("themes.layout.timeline.startTime.heading")}
            value={this.state.fromTime}
            options={getTimes()}
            placeholder={getMessage(
              "themes.layout.timeline.startTime.placeholder"
            )}
            onChange={(value) =>
              this.setLayoutSchedulerValues("fromTime", value)
            }
            required
            showError={this.state.errors.fromTime}
          />
        </div>
        <div className="timeline-block">
          <div className="timeline-date">
            <label htmlFor="toDate">
              {getMessage("themes.layout.timeline.endDate.heading")}
            </label>
            <SingleDatePicker
              value={this.state.toDate}
              id="toDate"
              onChange={(value) =>
                this.setLayoutSchedulerValues("toDate", value)
              }
              enableToday
              required
              showError={this.state.errors.toDate}
            />
          </div>
          <Select
            label={getMessage("themes.layout.timeline.endTime.heading")}
            value={this.state.toTime}
            options={getTimes()}
            placeholder={getMessage(
              "themes.layout.timeline.endTime.placeholder"
            )}
            onChange={(value) => this.setLayoutSchedulerValues("toTime", value)}
            required
            showError={this.state.errors.toTime}
          />
        </div>
      </div>
    );
  }

  getTimelineSelector() {
    let { timeLine, timeLines } = this.state;
    let value =
      timeLines &&
      timeLine &&
      (timeLines.filter((t) => t.value === timeLine)[0] || {}).text;
    return (
      isExtensionEnabled("LayoutSchedulingSupport") && (
        <div className="layout-timeline-dropdown">
          <label>{getMessage("themes.layout.timeline.heading")}</label>
          <DropDown value={value} icon={<img src={arrow} alt="^" />}>
            {this.state.timeLines &&
              this.state.timeLines.map((t) => {
                return (
                  timeLine !== t.value && (
                    <DropDownItem
                      key={t.value}
                      onClick={() => this.setLayoutTimeline(t.value)}
                    >
                      <div>{t.text}</div>
                    </DropDownItem>
                  )
                );
              })}
          </DropDown>
        </div>
      )
    );
  }

  getDeleteButton() {
    return (
      <div className="layout-delete-btn">
        <button
          onClick={() => this.setState({ showDeleteLayoutPopup: true })}
          className="delete-btn"
          title="Delete Layout"
        >
          <img src={deleteIcon} alt="delete" />
        </button>
        <Dialog
          show={this.state.showDeleteLayoutPopup}
          title={getMessage("themes.dialog.promt.title")}
          okText={getMessage("themes.dialog.okText")}
          closeText={getMessage("themes.dialog.closeText")}
          information={getMessage("themes.layout.delete.warning")}
          close={() =>
            this.setState({
              showDeleteLayoutPopup: false,
            })
          }
          onOk={this.handleDeleteSceduledLayout}
        />
      </div>
    );
  }

  listView() {
    const View = () => {
      if (this.state.loaded) {
        return (
          <div>
            <SortableList
              items={this.data}
              onSortEnd={this.onSortEnd}
              lockAxis="y"
              helperClass="is-dragging"
              onEditClick={this.onEditClick}
              onRemoveClick={this.showPrompt}
            />
            {this.pages[this.state.page] !== "Splash Screen" &&
              (this.pages[this.state.page] !== "footer" ||
                this.data?.length === 0) && (
                <div className="add-layout-section">
                  ——<span className="xs-hidden">———————</span>{" "}
                  <button className="add-layout-btn" onClick={this.onAddClick}>
                    {getMessage("themes.add.text")}
                  </button>{" "}
                  ——<span className="xs-hidden">——————</span>
                </div>
              )}

            <Popup
              show={this.state.showLayoutPopup}
              heading={getMessage("themes.selection.heading")}
              className="add-new-layout-popup"
              close={(e) =>
                this.setState({
                  showLayoutPopup: false,
                })
              }
            >
              {
                <div className="layout-main-content">
                  <ul>
                    {Object.keys(availableComponents).map(
                      (component, index) => {
                        const isSplashScreen =
                          this.pages[this.state.page] === "Splash Screen";
                        const isFooterPage =
                          this.pages[this.state.page] === "footer";
                        if (
                          (isSplashScreen && component !== "Splash") ||
                          (!isSplashScreen && component === "Splash") ||
                          (isFooterPage && component !== "FooterLayout") ||
                          (!isFooterPage && component === "FooterLayout")
                        ) {
                          return null; // Skip rendering this component
                        }
                        return (
                          <li
                            key={`change-components-${index}`}
                            className={
                              this.state.currentComponent === component
                                ? "active-layouts"
                                : ""
                            }
                            onClick={(e) => {
                              this.getRespectiveComponents(component);
                            }}
                          >
                            {component.replace(/([A-Z])/g, " $1").trim()}
                          </li>
                        );
                      }
                    )}
                  </ul>
                  <div
                    className="ui-components"
                    onClick={this.props.onComponentClick}
                  >
                    {Object.keys(
                      availableComponents[this.state.currentComponent]
                    )
                      .filter(
                        (layout) =>
                          !(
                            this.props.theme.name !== "Trends" &&
                            dualImageLayouts.includes(layout)
                          )
                      )
                      .map((layout, index) => {
                        const Component =
                          availableComponents[this.state.currentComponent][
                            layout
                          ];
                        let data = {};
                        if (layout === "BannerWithButton") {
                          data.button = true;
                        }
                        let categoryAndbrandCollection =
                          layout === "SubCategoryCollection";
                        return (
                          <div key={`add-layout-${index}`}>
                            <h3>
                              {layout.replace(/([A-Z])/g, " $1").trim()}{" "}
                              {categoryAndbrandCollection && (
                                <small>Beta</small>
                              )}
                            </h3>
                            <div className="layout-option">
                              <Component
                                data={data}
                                page={this.pages[this.state.page]}
                              />
                              <div className="toolbar">
                                <button
                                  className="primary button"
                                  onClick={(e) => {
                                    this.addLayout(layout, data);
                                  }}
                                >
                                  {getMessage("themes.selection.add")}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              }
            </Popup>
            <Dialog
              show={this.state.showDialog}
              className={this.state.error ? "warn" : "success"}
              title={
                this.state.error
                  ? getMessage("themes.layout.save.title.error")
                  : getMessage("themes.layout.save.title.success")
              }
              information={
                this.state.error
                  ? getMessage("themes.error." + this.state.error)
                  : getMessage("themes.layout.save.success")
              }
              closeText={getMessage("themes.dialog.okText")}
              close={(e) => {
                this.setState({
                  showDialog: false,
                });
              }}
            />
            <Dialog
              show={this.state.showPrompt}
              title={getMessage("themes.dialog.promt.title")}
              okText={getMessage("themes.dialog.okText")}
              closeText={getMessage("themes.dialog.closeText")}
              information={getMessage("themes.layout.delete.warning")}
              close={() =>
                this.setState({
                  showPrompt: false,
                  deleteIndex: "",
                })
              }
              onOk={() => this.onRemoveClick(this.state.deleteIndex)}
            />
          </div>
        );
      }
      return <Loader />;
    };
    let selectForCategoryTagBrandSelection = "";
    let selectScheduler = this.getTimelineSelector();
    let datePicker =
      this.state.timeLine !== "default" &&
      (this.state.timeLine === "new"
        ? this.getSchedulerForm()
        : this.getDeleteButton());
    switch (this.pages[this.state.page]) {
      case "category":
        selectForCategoryTagBrandSelection = this.getSelectElement(
          "category",
          "categories"
        );
        break;
      case "tag":
        selectForCategoryTagBrandSelection = this.getSelectElement(
          "tag",
          "tags"
        );
        break;
      case "brand":
        selectForCategoryTagBrandSelection = this.getSelectElement(
          "brand",
          "brands"
        );
        break;
      default:
        selectForCategoryTagBrandSelection = "";
    }
    return (
      <div className="themes-customize">
        <Dialog
          show={this.state.showSaveDialog}
          className={"success"}
          title={getMessage("success.text.title")}
          information={getMessage("themes.customize.save.success")}
          closeText={getMessage("ok.text")}
          close={(e) => {
            this.setState({
              showSaveDialog: false,
            });
          }}
        />
        <Dialog
          show={this.state.showErrorDialog}
          className={"warn"}
          title={getMessage("themes.customize.error.msg.title")}
          information={getMessage("themes.customize.error.msg")}
          closeText={getMessage("ok.text")}
          close={(e) => {
            this.setState({
              showErrorDialog: false,
            });
          }}
        />
        <h2>
          {getMessage("themes.customize.subheading", {
            name: this.props.theme.name,
          })}
        </h2>
        <Tabs
          items={this.pages.map((page) =>
            specialLayout?.includes(page) ? page : `${page} page`
          )}
          default={this.state.page}
          onClick={(index) => {
            this.changePage(index);
          }}
        />
        {!specialLayout?.includes(this.pages[this.state.page]) && (
          <div
            className={
              "layout-scheduler-controller" +
              (this.state.timeLine === "new" ? " column" : "")
            }
          >
            {this.pages[this.state.page] === "tag" && (
              <div className="filter-checkbox">
                <Checkbox
                  className="availability-status"
                  inlineLabel={getMessage("themes.tag.filter.label")}
                  name="status"
                  value={this.state.showFilter}
                  onChange={(value) => this.setState({ showFilter: value })}
                />
              </div>
            )}
            {datePicker}

            <div className="inline-block">
              {selectScheduler}
              {selectForCategoryTagBrandSelection}
            </div>
          </div>
        )}
        <View />
        {this.state.loaded && (
          <div className="button-wrapper">
            <button
              className="button button-white"
              type="button"
              onClick={this.cancelReordering}
            >
              {getMessage("order.form.cancel.text")}
            </button>
            <button
              className="button primary"
              type="button"
              onClick={this.reorderLayout}
            >
              save
            </button>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { props } = this;
    const View = () => {
      if (this.state.editing) {
        const layout = this.data[this.state.layoutIndex];
        const layoutInfo =
          getLayout(
            this.state.page,
            layout.name,
            this.pages,
            this.selectOptionsData
          ) || {};
        const top = this.currentLayout.top;
        return (
          <Layout
            top={top}
            data={{ ...layout.data, ...layout.value, name: layout.name }}
            preview={layoutInfo.preview}
            fields={layoutInfo.fields}
            name={layout.name}
            page={this.pages[this.state.page]}
            onSubmit={(form) => {
              this.saveLayouts(this.state.layoutIndex, form);
            }}
            onCancel={() => {
              this.setState({
                editing: false,
              });
            }}
            onRemove={() => {
              console.info("Remove this layout");
            }}
            sortable
          />
        );
      }
      return <this.listView />;
    };
    return (
      <AuthenticatedPage
        menu={props.menu}
        className="website"
        showLanguageDropDown
      >
        <div className="themes-customize">
          <div className="themes-customize-heading">
            <h1>{getMessage("themes.heading")}</h1>
            <Link to="/settings/themes/navigation" className="primary button">
              {getMessage("themes.customize.nav")}
            </Link>
          </div>
          <div className="horizontal-line">
            <hr />
          </div>
          {this.state.showLoader ? (
            <Loader />
          ) : (
            <AnnouncementBar
              announcementBar={this.state.announcementBar}
              isAnnouncementBarEnabled={this.state.isAnnouncementBarEnabled}
              handleAnnouncementBarSave={this.handleAnnouncementBarSave}
              announcementBarSubmitting={this.state.announcementBarSubmitting}
              handleOnChange={this.handleOnChange}
              description={this.state.values.description}
              updateState={this.updateState}
            />
          )}
          <div className="horizontal-line">
            <hr />
          </div>
        </div>
        <View />
      </AuthenticatedPage>
    );
  }
}
