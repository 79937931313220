import React from "react";
import {
  BaseForm,
  Input,
  Upload,
  Searchable,
  VALIDATION_TYPES,
} from "../../../../components/Form";
import RichEditor, { importText } from "../../../../components/RichEditor";
import { getMessage } from "../../../../lib/translator";
import "./style.css";
import { Helmet } from "react-helmet";
import { get } from "../../../../lib/storage";

export default class RecipeForm extends BaseForm {
  constructor(props) {
    super(props);
    this.data = [];
    this.direction = get("dataLang") === "ar" ? "rtl" : "ltr";
  }

  renderRichEditor = (sectionKey, headingKey, placeholderKey) => (
    <>
      <div className="description-tabs">
        <span className="selected">{getMessage(headingKey)}</span>
      </div>
      <RichEditor
        key={`editor-${headingKey}`}
        placeholder={getMessage(placeholderKey)}
        value={importText(this.state.values[sectionKey])}
        onChange={(value) => this.updateState([sectionKey], value)}
      />
    </>
  );

  render() {
    const { CancelButton, SubmitButton } = this.buttons;
    const { Form } = this.components;

    return (
      <div>
        <Helmet title="Zopping - Add Recipe" />
        <Form className="recipe-form">
          <Input
            className="title-input"
            label={getMessage("marketing.recipe.form.title.heading")}
            placeholder={getMessage("marketing.recipe.form.title.placeholder")}
            name="name"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage(
                "marketing.recipe.form.title.requiredMessage"
              ),
            }}
            dir={this.direction}
          />

          <Input
            className="title-input"
            label={getMessage(
              "marketing.recipe.form.description.title.heading"
            )}
            placeholder={getMessage(
              "marketing.recipe.form.description.title.placeholder"
            )}
            name="description"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["description"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            dir={this.direction}
          />
          <Upload
            label={getMessage(
              "marketing.recipe.form.thumbnail.image.title.heading"
            )}
            placeholder={getMessage("brand.form.image.placeholder")}
            {...this.generateStateMappers({
              stateKeys: ["thumbnail"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Upload
            label={getMessage(
              "marketing.recipe.form.featured.image.title.heading"
            )}
            placeholder={getMessage("brand.form.image.placeholder")}
            {...this.generateStateMappers({
              stateKeys: ["image"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />

          <Searchable
            label={getMessage("marketing.recipe.form.product.title.heading")}
            placeholder={getMessage(
              "marketing.recipe.form.product.add.placeholder"
            )}
            name="Product"
            searchUrl="/catalogue-service/product"
            valueKey="id"
            responseKey="tag"
            nameKey="name"
            searchKey="name"
            dir={this.direction}
            transformResponse={(response) => response.data.product}
            multiple
            {...this.generateStateMappers({
              stateKeys: ["products"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />

          {this.renderRichEditor(
            "ingredients",
            "marketing.recipe.form.ingredients.heading",
            "marketing.recipe.form.ingredients.placeholder"
          )}
          {this.renderRichEditor(
            "directions",
            "marketing.recipe.form.directions.heading",
            "marketing.recipe.form.directions.placeholder"
          )}
          {this.renderRichEditor(
            "metadata",
            "marketing.recipe.form.metadata.heading",
            "marketing.recipe.form.metadata.placeholder"
          )}

          <div className="form-buttons">
            <SubmitButton>{getMessage("save.text")}</SubmitButton>
            <CancelButton>{getMessage("cancel.text")}</CancelButton>
          </div>
        </Form>
      </div>
    );
  }
}
