import React from "react";
import { Input, Upload } from "../../../../components/Form";
import Tabs from "../../../../components/Tabs";
import FooterLayout from "../../../../components/Layout/Footer";
import { getMessage } from "../../../../lib/translator";
// import "./style.css";

const Footer = props => {
  const { getState, updateState } = props;

  const emptyItem = { text: "", url: "", image: "" };
  const emptySection = { text: "", items: [emptyItem] };
  const sections = getState(["sections"]) || [emptySection];
  const activeSectionIndex = Number(getState(["activeSectionIndex"])) || 0;
  const activeItemIndex = Number(getState(["activeItemIndex"])) || 0;

  const activeSection = sections[activeSectionIndex] || emptySection;
  const activeItem = activeSection.items[activeItemIndex] || emptyItem;

  // Section Management
  const addSection = () => {
    const newSections = [...sections, { text: "", items: [emptyItem] }];
    updateState(["sections"], newSections);
    updateState(["activeSectionIndex"], newSections.length - 1);
    updateState(["activeItemIndex"], 0);
  };

  const deleteSection = index => {
    const updatedSections = sections.filter((_, i) => i !== index);
    updateState(["sections"], updatedSections);
    updateState(["activeSectionIndex"], Math.max(0, index - 1));
    updateState(["activeItemIndex"], 0);
  };

  const updateSectionText = text => {
    const updatedSections = [...sections];
    updatedSections[activeSectionIndex].text = text;
    updateState(["sections"], updatedSections);
  };

  // Item Management within a Section
  const addItem = () => {
    const updatedSections = [...sections];
    updatedSections[activeSectionIndex].items.push(emptyItem);
    updateState(["sections"], updatedSections);
    updateState(
      ["activeItemIndex"],
      updatedSections[activeSectionIndex].items.length - 1
    );
  };

  const deleteItem = index => {
    const updatedSections = [...sections];
    updatedSections[activeSectionIndex].items.splice(index, 1);
    updateState(["sections"], updatedSections);
    updateState(["activeItemIndex"], Math.max(0, index - 1));
  };

  const updateItem = (key, value) => {
    const updatedSections = [...sections];
    updatedSections[activeSectionIndex].items[activeItemIndex][key] = value;
    updateState(["sections"], updatedSections);
  };

  return (
    <>
      {/* Section Tabs */}
      <div className="flex-around">
        <Tabs
          items={sections.map((section, index) => (
            <span key={index}>
              {`Section ${index + 1}`}
              {sections.length > 1 && (
                <span
                  className="delete-tab"
                  onClick={() => deleteSection(index)}
                >
                  &times;
                </span>
              )}
            </span>
          ))}
          default={activeSectionIndex}
          active={activeSectionIndex}
          onClick={value => {
            updateState(["activeSectionIndex"], value);
            updateState(["activeItemIndex"], 0); // Reset item index on section change
          }}
        />
        <button
          className="add-tab"
          onClick={e => {
            e.preventDefault();
            addSection();
          }}
        >
          + {getMessage("footer.addSection")}
        </button>
      </div>

      {/* Section Content */}
      <div className="form-sections">
        <div className="form-section">
          <Input
            label="Section Title"
            placeholder="Enter section title here"
            value={activeSection.text}
            onChange={value => updateSectionText(value)}
            required
          />
        </div>
      </div>

      {/* Item Tabs */}
      <div className="flex-around">
        <Tabs
          items={activeSection.items.map((item, index) => (
            <span key={index}>
              {`Item ${index + 1}`}
              {activeSection.items.length > 1 && (
                <span className="delete-tab" onClick={() => deleteItem(index)}>
                  &times;
                </span>
              )}
            </span>
          ))}
          default={activeItemIndex}
          active={activeItemIndex}
          onClick={value => updateState(["activeItemIndex"], value)}
        />
        <button
          className="add-tab"
          onClick={e => {
            e.preventDefault();
            addItem();
          }}
        >
          + {getMessage("footer.addItem")}
        </button>
      </div>

      {/* Item Content */}
      <div className="form-sections image-with-text-layout">
        <div className="form-section">
          <Input
            label="Item Name"
            placeholder="Enter text here"
            value={activeItem.text}
            onChange={value => updateItem("text", value)}
            required
          />
        </div>
        <div className="form-section">
          <Input
            label="Item URL"
            placeholder="Enter URL here"
            value={activeItem.url}
            onChange={value => updateItem("url", value)}
            required
          />
        </div>
        <div className="form-section">
          <Upload
            label="Image"
            placeholder="Click here to upload or drag your images"
            value={activeItem.image}
            onChange={value => updateItem("image", value)}
          />
        </div>
      </div>
    </>
  );
};

const FooterWrapper = () => {
  return {
    preview: FooterLayout,
    fields: ({ getState, updateState }) => (
      <Footer getState={getState} updateState={updateState} />
    ),
  };
};

export default FooterWrapper;
