import React from "react";
import { BaseForm, Upload } from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";
import MOBILE2 from "./Mobile2.svg";

const resolutionsAndroid = [
  //andriod
  { width: 200, height: 320 },
  { width: 320, height: 480 },
  { width: 480, height: 800 },
  { width: 720, height: 1280 },
  { width: 960, height: 1600 },
  { width: 1280, height: 1920 },
];

// ios
const resolutionsIos = [
  { width: 640, height: 1136 },
  { width: 750, height: 1334 },
  { width: 828, height: 1792 },
  { width: 1125, height: 2436 },
  { width: 1242, height: 2208 },
  { width: 1242, height: 2688 },
  { width: 1536, height: 2048 },
  { width: 1668, height: 2224 },
  { width: 2048, height: 2732 },
];

class AndroidForm extends BaseForm {
  handleUpdateSplashMdpi = (e) => {
    const { updateState } = this.props;
    updateState(["config", "androidAppConfig", "splashMdpi"], e);
    updateState(["config", "iosAppConfig", "splashMdpi"], e);
  };

  render() {
    const { Form } = this.components;
    const { getState } = this.props;
    const splashMdpi = getState(["config", "androidAppConfig", "splashMdpi"]);

    return (
      <>
        <Form className="splash-form">
          <div className="icon-upload-container">
            <div className="form-container-child">
              <div className="form-fields">
                <Upload
                  className="app-splash-screen"
                  label={getMessage("mobileApps.splashScreen.heading")}
                  name="appIcon"
                  resolutions={[resolutionsAndroid, resolutionsIos]}
                  value={splashMdpi}
                  onChange={this.handleUpdateSplashMdpi}
                  placeholder="Upload Splash Screen"
                  required
                  validationStrings={{
                    valueMissing: getMessage("input.requiredMessage"),
                    fileSizeExeeded: getMessage("input.fileSizeExeeded"),
                    invalidFileType: getMessage("input.invalidFileType"),
                  }}
                />
                <div className="label">
                  {getMessage("mobileApps.longDescription.suggestion")}
                </div>
              </div>
            </div>
            <div className="image-container-child">
              <div className="inner-image-container">
                <img
                  src={MOBILE2}
                  alt="Mobile Frame"
                  width="144px"
                  height="294px"
                />
                <div className="splash-notch-container" />
                <div className="splash-container-child">
                  <img
                    className="splash-image-border"
                    alt="Splash Screen"
                    src={splashMdpi?.imageUpload?.url ?? splashMdpi}
                    width="128px"
                    height="278px"
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </>
    );
  }
}

export default AndroidForm;
