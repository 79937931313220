import React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import { BaseForm, Input, VALIDATION_TYPES } from "../../../../components/Form";
import { cloneMutables } from "../../../../lib/stateManagement/";
import { BannerWithText, BannerWithMultipleButtons } from "zopsmart-ui";
import { Popup, Dialog } from "../../../../components/Popup";
import { getMessage } from "../../../../lib/translator";
import { getSession } from "../../../../lib/auth";

import Layout from "../../../settings/Themes/layout";
import getLayout from "../../../settings/Themes/Layouts/getLayout";
import Banner from "../../../../components/Layout/Banner";
import RichText from "../../../../components/Layout/RichText";
import ImageWithText from "../../../../components/Layout/ImageWithText";
import "./style.css";
import API from "../../../../lib/api";
import Loader from "../../../../components/Loader";
import { get } from "../../../../lib/storage";

function getSlugFromText(text) {
  if (!text) {
    return "";
  }
  return text.toLowerCase().replace(/\s+/g, "-");
}

const SortableItem = SortableElement((props) => {
  const { value, layoutIndex, onEditClick, onRemoveClick } = props;
  let LayoutComponent = "";
  Object.keys(availableComponents).forEach(function (component, index) {
    if (availableComponents[component][value.name]) {
      LayoutComponent = availableComponents[component][value.name];
    }
  });
  let element = "";
  const isShowLayout = typeof LayoutComponent !== "string";
  return (
    <div className="layoutItem">
      {isShowLayout && (
        <>
          <h3>
            <DragHandle>
              {value.name.replace(/([A-Z])/g, " $1").trim()}
            </DragHandle>
          </h3>
          <div
            className={value.name + " editable layout"}
            ref={(el) => {
              element = el;
            }}
          >
            <LayoutComponent data={value.data} />
            <div className="toolbar">
              {!["MarketingBanners"].includes(value.name) && (
                <button
                  className="primary button"
                  onClick={(e) => {
                    onEditClick(layoutIndex, element.offsetTop);
                  }}
                >
                  Edit
                </button>
              )}
              <button
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  onRemoveClick(layoutIndex);
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

const SortableList = SortableContainer((props) => {
  return (
    <div className="layoutsContainer">
      {Array.isArray(props.items) &&
        props.items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            layoutIndex={index} // for some reason index was not being sent as props
            {...props}
          />
        ))}
    </div>
  );
});

// Support only for Fashion Trends theme as of now
const dualImageLayouts = ["DualImageBanner", "DualImage"];

const availableComponents = {
  Banners: {
    MarketingBanners: Banner,
    BannerWithText: BannerWithText,
    BannerWithButton: BannerWithText,
    BannerWithMultipleButtons: BannerWithMultipleButtons,
  },
  Images: {
    ImageWithText: ImageWithText,
  },
  Text: {
    RichText: RichText,
  },
};

const DragHandle = SortableHandle((props) => (
  <span className="sortable-handle">{props.children}</span>
));

export default class WebsitePageForm extends BaseForm {
  constructor(props) {
    super(props);
    this.domain = getSession().organization.domain;
    this.state.showDialog = false;
    this.direction = get("dataLang") === "ar" ? "rtl" : "ltr";
    let content =
      this.state.values.content && this.state.values.content.filter(Boolean);
    if (!content) {
      this.state.values = {
        content: [],
      };
    }
    this.state.showLayoutPopup = false;
    this.state.showDialog = false;
    this.state.currentComponent = "Banners";
    this.pages = ["staticPage"];
    this.data = [];
    this.currentLayout = {};
    this.selectOptionsData = {
      categories: [],
      tags: [],
      brands: [],
    };
  }
  getSelectOptionsData(selectField, dataFor) {
    const api = new API({
      url: `/catalogue-service/${selectField}`,
    });

    api
      .get()
      .then((response) => {
        if (response.status === "ERROR") {
          console.error(`Error in fetching details of ${dataFor}`);
          return;
        }
        this.selectOptionsData[dataFor] = [];
        if (!response.data[selectField]) {
          response.data[selectField] = [];
        }
        this.selectOptionsData[dataFor] = response.data[selectField].map(
          function (field) {
            return { text: field.name, value: field.slug };
          }
        );
        this.selectOptionsData[dataFor].push(
          { text: `All ${dataFor}`, value: "ALL" },
          { text: `Current ${selectField}`, value: "CURRENT" }
        );
        const state = `${dataFor}DataLoaded`;
        this.setState({
          loaded: true,
          [state]: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchScheduledLayout() {
    this.setState({
      loaded: false,
    });
    const page = this.pages[0];
    let url = `/website-service/layout`;
    let params = { id: "staticPage", url: this.state.values.url };

    if (this.state[page]) {
      params.url = this.state[page];
    }

    const api = new API({
      url: url,
    });
    api.get(params).then((response) => {
      if (response.status === "ERROR") {
        console.error(`Error in fetching details for ${page}`); // Todo: Handle this error
        return;
      }
      if (!response.data?.page?.length) {
        this.data = [];
        this.setState({ loaded: true });
        return;
      }
      this.pageId = response.data?.page?.[0].pageId;
      this.data = this.formatResponse(response.data.page[0].layouts);
      this.originalData = this.data;

      if (!this.state.categoryDataLoaded) {
        this.getSelectOptionsData("category", "categories");
      }
      if (this.pages.includes("tag")) {
        if (!this.state.tagDataLoaded) {
          this.getSelectOptionsData("tag", "tags");
        }
      }
      if (this.pages.includes("brand")) {
        if (!this.state.brandDataLoaded) {
          this.getSelectOptionsData("brand", "brands");
        }
      }
    });
  }

  componentDidMount() {
    this.fetchScheduledLayout();
  }

  addLayout = (layoutName, data) => {
    const layout = {
      name: layoutName,
      data: data,
    };
    this.data.push(layout);
    this.setState({
      showLayoutPopup: false,
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.data = arrayMove(this.data, oldIndex, newIndex);
    this.forceUpdate();
  };

  onSubmit = (values) => {
    this.setState(
      (prevState) => Object.assign({}, prevState, { values }),
      this._submitHandler
    );
  };

  getRespectiveComponents = (component) => {
    this.setState({
      currentComponent: component,
    });
  };

  onEditClick = (layoutIndex, top) => {
    const layout = this.data[layoutIndex];
    Object.assign(this.currentLayout, layout);
    this.currentLayout.top = top;
    this.setState({
      editing: true,
      layoutIndex,
    });
  };

  onRemoveClick = (index) => {
    this.saveLayouts(index);
    this.setState({
      showPrompt: false,
      deleteIndex: "",
    });
  };

  showPrompt = (index) => {
    this.setState({
      showPrompt: true,
      deleteIndex: index,
    });
  };

  onAddClick = () => {
    this.setState({
      showLayoutPopup: true,
    });
  };

  listView = () => {
    const View = () => {
      if (this.state.loaded) {
        return (
          <>
            <SortableList
              items={this.data}
              onSortEnd={this.onSortEnd}
              lockAxis="y"
              helperClass="is-dragging"
              onEditClick={this.onEditClick}
              onRemoveClick={this.showPrompt}
            />
            <div className="add-layout-section">
              ——<span className="xs-hidden">———————</span>
              <button className="add-layout-btn" onClick={this.onAddClick}>
                {getMessage("themes.add.text")}
              </button>
              ——<span className="xs-hidden">——————</span>
            </div>
          </>
        );
      }
      return <Loader />;
    };
    return (
      <div className="themes-customize">
        <View />
        {this.state.loaded && (
          <div className="button-wrapper">
            <button
              className="button button-white"
              type="button"
              onClick={this.cancelReordering}
            >
              {getMessage("order.form.cancel.text")}
            </button>
            <button
              className="button primary"
              type="button"
              onClick={this.reorderLayout}
            >
              save
            </button>
          </div>
        )}
      </div>
    );
  };

  formatResponse(response) {
    let newResponse = Object.assign([], response);
    const page = this.pages[this.state.page];
    newResponse.forEach((layout) => {
      if (
        layout.name === "CategoryCollection" ||
        layout.name === "BrandCollection"
      ) {
        let key = layout.name === "CategoryCollection" ? "category" : "brand";
        let collection = (layout.value && layout.value.collection) || [];
        collection = collection.map((val) => val[key]);
        layout["data"][key] = collection;
        layout.value && delete layout.value.collection;
      }
      if (
        layout.name === "CategoryCollection" &&
        layout.data &&
        layout.data.subcategories &&
        page === "category"
      ) {
        layout.name = "SubCategoryCollection";
      }
    });
    return newResponse;
  }

  saveLayouts = (index, formData = null) => {
    this.setState({
      submitting: true,
    });
    // if (formData && formData.name === AppointmentFormLayout) {
    //   this.appointmentForm(formData);
    //   return;
    // }

    let data = this.data;
    if (!formData) {
      data =
        index !== undefined
          ? this.data.filter((_, idx) => idx !== index)
          : this.data;
    } else {
      data = data.filter((_, i) => i !== index);
      let layoutname = formData.name;
      delete formData.name;
      data.splice(index, 0, { name: layoutname, data: { ...formData } });
    }
    if (!index && index !== 0) {
      data = this.data;
    }
    const page = this.pages[0];
    let url = `/website-service/layout/${page}`;
    data &&
      data.forEach((layout, i) => {
        const layoutName = layout.name;
        if (layoutName === "SubCategoryCollection") {
          layout.name = "CategoryCollection";
          layout.data.subcategories = true;
        }

        if (layoutName === "CategoryCollection") {
          if (formData && i === index) {
            layout.data.categoryIds =
              layout.data.category &&
              layout.data.category
                .map((category) => category.id)
                .filter(Boolean);
            delete layout.data.category;
          }
        }
        if (layoutName === "BlogCollection") {
          if (formData && i === index) {
            layout.data.blogIds =
              layout.data.blog &&
              layout.data.blog.map((blog) => blog.id).filter(Boolean);
            delete layout.data.blog;
          }
        }
        if (layoutName === "BrandCollection") {
          if (formData && i === index) {
            layout.data.brandIds =
              layout.data.brand &&
              layout.data.brand.map((brand) => brand.id).filter(Boolean);
          }
          delete layout.data.brand;
        }
        if (layoutName === "ProductCollection") {
          layout.data && delete layout.data.collection;
          if (layout.data.hasImage === -1) {
            layout.data.hasImage = "";
          }
          if (layout.data.hasStock === -1) {
            layout.data.hasStock = "";
          }
          if (layout.data.hasOffers === -1) {
            layout.data.hasOffers = "";
          }
          if (
            layout.data.category &&
            typeof layout.data.category === "object"
          ) {
            layout.data.category = layout.data.category.slug;
          }
          if (layout.data.brand && typeof layout.data.brand === "object") {
            layout.data.brand = layout.data.brand.slug;
          }
          if (layout.data.tag && typeof layout.data.tag === "object") {
            layout.data.tag = layout.data.tag.slug;
          }
        }
        if (layout.name === "ImageSlideShow") {
          layout.data &&
            layout.data.activeIndex &&
            delete layout.data.activeIndex;
        }

        // if (layout.name === AppointmentFormLayout) {
        //   layout.data = {};
        // }

        delete layout.value;
        layout.data && delete layout.data.collection;
      });

    const api = new API({
      url: url,
    });
    let params = {
      layouts: data,
      url: this.state.values?.url,
    };

    // if (page === 'tag' && !this.state.hideFilter) {
    //   params.hideFilter = true
    // }

    if (page === "tag") {
      params.showFilter = this.state.showFilter;
    }
    if (
      page !== "home" &&
      this.state[page] !== "" &&
      this.state[page] !== "ALL" &&
      this.state[page]
    ) {
      params.url = `${this.state[page]}`;
    }

    api.put(params).then(
      (response) => {
        this.data = this.formatResponse(response.data.page.layouts);
        this.originalData = this.data;
        this.pageId = response.data.page.pageId;
        this.setState({
          editing: false,
          submitting: false,
          showDialog: true,
          error: false,
          errors: {},
        });
      },
      (error) => {
        this.setState({
          showDialog: true,
          error: error.message,
          submitting: false,
        });
      }
    );
  };

  reorderLayout = () => {
    this.saveLayouts();
  };

  render() {
    const { CancelButton } = this.buttons;
    const { Form } = this.components;
    const View = () => {
      if (this.state.editing) {
        const layout = this.data[this.state.layoutIndex];
        const layoutInfo =
          getLayout(
            this.state.page,
            layout.name,
            this.pages,
            this.selectOptionsData
          ) || {};
        const top = this.currentLayout.top;
        return (
          <Layout
            key={this.state.layoutIndex}
            top={top}
            data={{ ...layout.data, ...layout.value, name: layout.name }}
            preview={layoutInfo.preview}
            fields={layoutInfo.fields}
            name={layout.name}
            page={this.pages[this.state.page]}
            onSaveClick={(form) => {
              this.saveLayouts(this.state.layoutIndex, form);
            }}
            onCancel={() => {
              this.setState({
                editing: false,
              });
            }}
            onRemove={() => {
              console.info("Remove this layout");
            }}
            sortable
          />
        );
      }
      return <this.listView />;
    };
    return (
      <div className="website-page-edit">
        <Form className="website-page-form">
          <Input
            className="title-input"
            label={getMessage("websitePage.form.title.heading")}
            placeholder={getMessage("websitePage.form.title.placeholder")}
            name="title"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["title"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            onBlur={() => {
              if (!this.getState(["url"])) {
                this.updateState(
                  ["url"],
                  getSlugFromText(this.getState(["title"]))
                );
              }
            }}
            validationStrings={{
              valueMissing: getMessage(
                "websitePage.form.title.requiredMessage"
              ),
            }}
            dir={this.direction}
          />
          <Input
            className="url-input"
            label={getMessage("websitePage.form.url.heading")}
            placeholder={getMessage("websitePage.form.url.placeholder")}
            name="url"
            type="text"
            required
            prefix={`${this.domain}/pages/`}
            {...this.generateStateMappers({
              stateKeys: ["url"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
            dir={this.direction}
          />
          <View />

          <div className="add-layout">
            <div className={`add-layout`}>
              {this.state.showLayoutPopup && (
                <>
                  <Popup
                    show={this.state.showLayoutPopup}
                    heading={getMessage("themes.selection.heading")}
                    className="add-new-layout-popup"
                    close={(e) =>
                      this.setState({
                        showLayoutPopup: false,
                      })
                    }
                  >
                    {
                      <div className="layout-main-content">
                        <ul>
                          {Object.keys(availableComponents).map(
                            (component, index) => {
                              return (
                                <li
                                  key={`change-components-${index}`}
                                  className={
                                    this.state.currentComponent === component
                                      ? "active-layouts"
                                      : ""
                                  }
                                  onClick={(e) => {
                                    this.getRespectiveComponents(component);
                                  }}
                                >
                                  {component.replace(/([A-Z])/g, " $1").trim()}
                                </li>
                              );
                            }
                          )}
                        </ul>
                        <div
                          className="ui-components"
                          onClick={this.props.onComponentClick}
                        >
                          {Object.keys(
                            availableComponents[this.state.currentComponent]
                          )
                            .filter(
                              (layout) =>
                                !(
                                  this.props.theme?.name !== "Trends" &&
                                  dualImageLayouts.includes(layout)
                                )
                            )
                            .map((layout, index) => {
                              const Component =
                                availableComponents[
                                  this.state.currentComponent
                                ][layout];
                              let data = {};
                              if (layout === "BannerWithButton") {
                                data.button = true;
                              }
                              let categoryAndbrandCollection =
                                layout === "SubCategoryCollection";
                              return (
                                <div key={`add-layout-${index}`}>
                                  <h3>
                                    {layout.replace(/([A-Z])/g, " $1").trim()}
                                    {categoryAndbrandCollection && (
                                      <small>Beta</small>
                                    )}
                                  </h3>
                                  <div className="layout-option">
                                    <Component
                                      data={data}
                                      page={this.pages[this.state.page]}
                                    />
                                    <div className="toolbar">
                                      <button
                                        className="primary button"
                                        onClick={(e) => {
                                          this.addLayout(layout, data);
                                        }}
                                      >
                                        {getMessage("themes.selection.add")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    }
                  </Popup>
                </>
              )}
              <Dialog
                show={this.state.showDialog}
                className={this.state.error ? "warn" : "success"}
                title={
                  this.state.error
                    ? getMessage("themes.layout.save.title.error")
                    : getMessage("themes.layout.save.title.success")
                }
                information={
                  this.state.error
                    ? getMessage("themes.error." + this.state.error)
                    : getMessage("themes.layout.save.success")
                }
                closeText={getMessage("themes.dialog.okText")}
                close={(e) => {
                  this.setState({
                    showDialog: false,
                  });
                }}
              />
              <Dialog
                show={this.state.showPrompt}
                title={getMessage("themes.dialog.promt.title")}
                okText={getMessage("themes.dialog.okText")}
                closeText={getMessage("themes.dialog.closeText")}
                information={getMessage("themes.layout.delete.warning")}
                close={() =>
                  this.setState({
                    showPrompt: false,
                    deleteIndex: "",
                  })
                }
                onOk={() => this.onRemoveClick(this.state.deleteIndex)}
              />
            </div>
          </div>
          <div className="form-buttons">
            <button
              type="button"
              className="primary button"
              onClick={(e) => {
                e.preventDefault();
                let updates = {
                  isDraft: false,
                };
                if (!this.state.values.publishTime) {
                  let now = new Date();
                  updates.publishTime = `${now.getFullYear()}-${String(
                    now.getMonth() + 1
                  ).padStart(2, "0")}-${String(now.getDate()).padStart(
                    2,
                    "0"
                  )} ${now.toTimeString().split(" ")[0]}`;
                }
                this.onSubmit(
                  Object.assign({}, cloneMutables(this.state.values), updates)
                );
              }}
            >
              {getMessage("marketing.blog.form.submitText")}
            </button>
            <CancelButton>
              {getMessage("websitePage.form.cancelText")}
            </CancelButton>
          </div>
        </Form>
      </div>
    );
  }
}
